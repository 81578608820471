// src/components/MetaMaskConnect.js
import React, { useState } from 'react';
import { Box, Button, Container, Paper, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';



const MetaMaskConnect = ({ onConnectWallet, connectButtonText, onStoreNumber, number, inputValue, setInputValue, buttonText, buttonDisabled, buttonColor, onDisconnectWallet }) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleStoreNumber = async () => {
        await onStoreNumber();
    };
    return (
        <Box>
            <Container component="main" maxWidth="xs">
                <Paper sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }} elevation={3}>
                    <Typography component="h1" variant="h5">
                        MM Mobile and Gas Demo
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        sx={{ width: '100%', mt: 1 }}
                    >
                        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                            Current Number: {number}
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="number"
                            label="Enter Number"
                            name="number"
                            autoFocus
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color={buttonColor}
                            disabled={buttonDisabled}
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleStoreNumber}
                        >
                            {buttonText}
                        </Button>
                        <Button
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            onClick={onConnectWallet}
                        >
                            {connectButtonText}
                        </Button>
                        {onDisconnectWallet && (
                            <Button
                                fullWidth
                                variant="outlined"
                                color="error"
                                sx={{ mt: 2 }}
                                onClick={onDisconnectWallet}
                            >
                                Disconnect
                            </Button>
                        )}
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

export default MetaMaskConnect;
