import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { MetaMaskProvider } from '@metamask/sdk-react';
import { SnackbarProvider } from 'notistack';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MetaMaskProvider debug={false} sdkOptions={{
        dappMetadata: {
          name: "Wallet Connect Test by Ferrum",
          url: window.location.host,
        }
        // Other options if needed
      }}>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </MetaMaskProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
