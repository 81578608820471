// src/components/HomePage.js

import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { useSDK } from '@metamask/sdk-react';
import contract, { CONTRACT_ABI, CONTRACT_ADDRESS } from '../contract';
import { useSnackbar } from 'notistack';
import MetaMaskConnect from './MetaMaskConnect';

// Initialize a web3 instance with a public RPC URL
const rpcUrl = "https://nd-605-906-592.p2pify.com/df9065025f5e18317e708040b1f2ab13";
const web3ForChecks = new Web3(rpcUrl);

function HomePage() {
    const [number, setNumber] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [account, setAccount] = useState('');
    const { sdk, connected } = useSDK();
    const { enqueueSnackbar } = useSnackbar();
    const [buttonText, setButtonText] = useState('Store Number');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonColor, setButtonColor] = useState('primary');
    const [connectButtonText, setConnectButtonText] = useState('Connect Wallet');

    // Connect to MetaMask Wallet
    const connectWallet = async () => {
        try {
            const accounts = await sdk?.connect();
            if (accounts && accounts.length > 0) {
                setAccount(accounts[0]);
                const shortAddress = `${accounts[0].substring(0, 6)}...${accounts[0].substring(accounts[0].length - 4)}`;
                setConnectButtonText(`Connected to ${shortAddress}`);
            }
        } catch (error) {
            console.error('Error connecting to MetaMask:', error);
            enqueueSnackbar('Error connecting to MetaMask', { variant: 'error' });
        }
    };

    const disconnectWallet = () => {
        // Clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();

        // Reset state
        setAccount('');
        setConnectButtonText('Connect Wallet');
        enqueueSnackbar('Disconnected from wallet', { variant: 'info' });
    };

    // Fetch the current number from the smart contract
    const fetchNumber = async () => {
        try {
            const contractInstance = new web3ForChecks.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
            const retrievedNumber = await contractInstance.methods.retrieveNumber().call();
            setNumber(retrievedNumber.toString());
        } catch (error) {
            console.error('Error retrieving number:', error);
        }
    };

    fetchNumber();

    useEffect(() => {
        if (connected) {
            fetchNumber();
        }
    }, [connected]);

    // Store a new number in the smart contract
    const storeNumber = async () => {
        if (!account) {
            alert('Please connect to MetaMask');
            return;
        }

        setButtonText('Transaction Processing');
        setButtonDisabled(true);
        setButtonColor('secondary');

        try {
            const transactionParameters = {
                from: account,
                to: CONTRACT_ADDRESS,
                data: contract.methods.storeNumber(inputValue).encodeABI(),
            };

            const txHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });

            enqueueSnackbar('Transaction submitted', { variant: 'info' });

            // Function to check transaction receipt
            const startTime = Date.now();
            const checkReceipt = async () => {
                console.log('Tracking transaction:', txHash);
                try {
                    const receipt = await web3ForChecks.eth.getTransactionReceipt(txHash);
                    if (receipt) {
                        if (receipt.status) {
                            console.log('Transaction mined:', receipt);
                            enqueueSnackbar('Transaction successful', { variant: 'success' });
                            fetchNumber();
                            setInputValue('');
                            setButtonText('Store Number');
                            setButtonDisabled(false);
                            setButtonColor('primary');
                        } else {
                            console.error('Transaction failed:', receipt);
                            enqueueSnackbar('Transaction failed', { variant: 'error' });
                            setButtonText('Store Number');
                            setButtonDisabled(false);
                            setButtonColor('primary');
                        }
                    } else {
                        // Receipt not available yet, check again after a delay
                        const currentTime = Date.now();
                        if (currentTime - startTime < 120000) { // 2 minutes timeout
                            setTimeout(checkReceipt, 10000); // Check again after 10 seconds
                        } else {
                            console.error('Transaction receipt not found within 2 minutes');
                            enqueueSnackbar('Transaction receipt not found', { variant: 'error' });
                        }
                    }
                } catch (error) {
                    if (error.message.includes('Transaction not found')) {
                        setTimeout(checkReceipt, 10000); // Check again after 10 seconds
                    } else {
                        console.error('Error checking transaction receipt:', error);
                        enqueueSnackbar('Error checking transaction receipt', { variant: 'error' });
                    }
                }
            };

            checkReceipt();
        } catch (error) {
            console.error('Error sending transaction:', error);
            enqueueSnackbar('Transaction failed', { variant: 'error' });
            setButtonText('Store Number');
            setButtonDisabled(false);
            setButtonColor('primary');
        }
    };

    return (
        <MetaMaskConnect
            onConnectWallet={connectWallet}
            connectButtonText={connectButtonText}
            onStoreNumber={storeNumber}
            number={number}
            inputValue={inputValue}
            setInputValue={setInputValue}
            buttonText={buttonText}
            buttonDisabled={buttonDisabled}
            buttonColor={buttonColor}
            onDisconnectWallet={connected ? disconnectWallet : null}
        />
    );
}

export default HomePage;