// src/components/WalletConnectTest.js
import { useWeb3Modal } from '@web3modal/ethers/react'
import React, { useState } from 'react';
import { Box, Button, Container, Paper, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useAccount, useConnect, useEnsName } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'

function Profile() {
  const { address, isConnected } = useAccount()
  const { data: ensName } = useEnsName({ address })
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  if (isConnected) return <div>Connected to {ensName ?? address}</div>
  return <button onClick={() => connect()}>Connect Wallet</button>
}


// const WalletConnectTest = ({ onConnectWallet, connectButtonText, onStoreNumber, number, inputValue, setInputValue, buttonText, buttonDisabled, buttonColor  }) => {
//     const { enqueueSnackbar } = useSnackbar();

//     const { open } = useWeb3Modal()

//     const handleStoreNumber = async () => {
//         await onStoreNumber();
//     };
//     return (
//             <Box>
//                 <Container component="main" maxWidth="xs">
//                     <Paper sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }} elevation={3}>
//                         <Typography component="h1" variant="h5">
//                             Wallet Connect Test
//                         </Typography>
//                         <Box
//                             component="form"
//                             noValidate
//                             sx={{ width: '100%', mt: 1 }}
//                         >
//                             <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
//                                 Current Number: {number}
//                             </Typography>
//                             <TextField
//                                 variant="outlined"
//                                 margin="normal"
//                                 required
//                                 fullWidth
//                                 id="number"
//                                 label="Enter Number"
//                                 name="number"
//                                 autoFocus
//                                 value={inputValue}
//                                 onChange={e => setInputValue(e.target.value)}
//                             />
//                             <Button
//                                 fullWidth
//                                 variant="contained"
//                                 color={buttonColor}
//                                 disabled={buttonDisabled}
//                                 sx={{ mt: 3, mb: 2 }}
//                                 onClick={handleStoreNumber}
//                             >
//                                 {buttonText}
//                             </Button>
//                             {/* <Button
//                                 fullWidth
//                                 variant="outlined"
//                                 color="secondary"
//                                 onClick={onConnectWallet}
//                             >
//                                 {connectButtonText}
//                             </Button> */}
//                             <Button
//                                 fullWidth
//                                 variant="outlined"
//                                 color="secondary"
//                                 onClick={() => open()}
//                             >
//                                 {connectButtonText}
//                             </Button>
//                         </Box>
//                     </Paper>
//                 </Container>
//             </Box>
//     );
// };

export default Profile;


